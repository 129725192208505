import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(items: any[], quantity: number): any {
    items.length = 0;
    for (let i = 1; i <= quantity; i++) {
      items.push(i);
    }
    return items;
  }

}
