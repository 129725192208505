import { Injectable } from "@angular/core";
import * as io from 'socket.io-client';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WebsocketService {

  // Conexión con el Socket
  private socket = io(environment.url);


    // Realizar conexión con el WS
    joinConnection(data)
    {
        let observable = new Observable<{nombre:String, topic:String}>(observer=>{
          this.socket.emit('joinTopic',data,(resp) => {
            observer.next(resp);
          });
        });

        return observable;
    }

    //Recibe los mensaje del servidor
    crearMensaje()
    {
        let observable = new Observable<{nombre:String, sala:String, type:String}>(observer=>{
            this.socket.on('crearMensaje', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });

        return observable;
    }

    //Recibe los usuarios actuales del servidor
    listarUsuarioConectado()
    {
        let observable = new Observable<{nombre:String, sala:String}>(observer=>{
            this.socket.on('listarUsuarioConectado', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });

        return observable;
    }

    //Recive mensaje provados por el servidor
    mensajePrivado()
    {
        let observable = new Observable<{nombre:String, sala:String}>(observer=>{
            this.socket.on('mensajeAprobacion', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });

        return observable;
    }
    

    //Salida del canal
    leaveChannel(data){
        this.socket.emit('leave',data);
    }

    //Close connection
    close(){
      this.socket.close();
      this.socket.disconnect();
    }

    userLeftRoom(){
        let observable = new Observable<{nombre:String, sala:String}>(observer=>{
            this.socket.on('left room', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });

        return observable;
    }

    sendMessagePrivate(data)
    {
        this.socket.emit('mensajePrivado',data);
    }

    newMessageReceived(){
        let observable = new Observable<{nombre:String, sala:String}>(observer=>{
            this.socket.on('new message', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
        return observable;
    }

    //Recibe mensaje por parte del cliente
    mensajeAprobacion()
    {
        let observable = new Observable<{nombre:String, sala:String}>(observer=>{
            this.socket.on('mensajeAprobacion', (data)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });

        return observable;
    }

    //Eliminar suscripción
    deleteSuscripcion(topic)
    {
        //this.socket.disconnect(topic);
        let observable = new Observable<{ topic:String}>(observer=>{
            this.socket.emit('leaveTopic',topic,(resp) => {
                observer.next(resp);
              });
          });
        return observable;
        
    }
}
