import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MatDialogRef } from '@angular/material';
import {API_MAIN_URL} from "../constants";

@Component({
  selector: 'app-dialog-register',
  templateUrl: './dialog-register.component.html',
  styleUrls: ['./dialog-register.component.scss']
})
export class DialogRegisterComponent implements OnInit {

  url: string = API_MAIN_URL;
  ingresar: FormGroup;
  hide = true;
  checked = false;
  email = new FormControl('', [Validators.required, Validators.email]);
  emailConfir = new FormControl('', [Validators.required, Validators.email]);

  emailuser: string;
    nameuser: string;
    apellidouser: string;
    telefonouser: string;

  constructor(private router: Router,
    public dialogRef: MatDialogRef<DialogRegisterComponent>) { 

      this.emailuser = localStorage.getItem('emailuser') != null ? localStorage.getItem('emailuser') : '';
      this.nameuser = localStorage.getItem('nameuser') != null ? localStorage.getItem('nameuser') : '';
      this.apellidouser = localStorage.getItem('apellidouser') != null ? localStorage.getItem('apellidouser') : '';
      this.telefonouser = localStorage.getItem('cellphoneUser') != null ? localStorage.getItem('cellphoneUser') : '';
    }

  ngOnInit() {
  }

  onSave(){

    this.dialogRef.close();
    this.router.navigate(['/dashboard',localStorage.getItem('clientId')]);

  }

  onClose(){
    this.dialogRef.close();
    this.router.navigate(['/clientnotengie']);
  }
}
