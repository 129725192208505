import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../core/api/client/client.service';
import {SnackbarService} from 'src/app/core/api/snackbar.service';
import {MatTableDataSource} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TutenEngieClientIndentifier} from '../../core/api/client/client-interfaces';
import { API_MAIN_URL } from '../shared/constants';

@Component({
  selector: 'app-servi2-accounts',
  templateUrl: './servi2-accounts.component.html',
  styleUrls: ['./servi2-accounts.component.scss']
})
export class Servi2AccountsComponent implements OnInit {

  @Input()
  url: string = API_MAIN_URL;

  displayedColumns = ['select', 'interlocutor', 'cuentaContrato', 'numeroDeInstalacion', 'numExterior', 'numInterior', 'details'];
  accounts: MatTableDataSource<any>;
  userId: any;
  accontForm: FormGroup;
  confirmation: FormGroup;
  validate = false;
  addEngieClientIndetifiers: TutenEngieClientIndentifier;
  deleteEngieClientIndetifiers: TutenEngieClientIndentifier;
  account = false;
  hide = true;
  confirmDelete = false;
  action = false;
  row: any;
  newAccount: any;
  postAccount: any;
  confirmationDelete: any;
  accountsFirst: any;
  interLoNoEquals = true;
  interlocutorDisabled = true;

  constructor(private _location: Location,
              private snackBarService: SnackbarService,
              private clientService: ClientService,
              private formBuilder: FormBuilder,
              private router: Router) {

    this.accontForm = new FormGroup({
      'interlocutor': new FormControl(''),
      'cuenta': new FormControl(''),
      'instalacion': new FormControl(''),
      'password': new FormControl('')
    });

    this.confirmation = new FormGroup({
      'password': new FormControl('')
    });

    this.confirmationDelete = new FormGroup({
      'password': new FormControl('')
    });

    this.accontForm.controls.interlocutor.valueChanges.subscribe( data => {
      if (data == this.accountsFirst[0].interlocutor){
        this.interLoNoEquals = false;
      }
    });
  }

  ngOnInit() {
    this.getServi2Accounts();
    this.userId = this.clientService.getClientId();
  }

  getServi2Accounts() {
    this.clientService.getServi2Accounts().subscribe((response: any) => {
      let accounts = response.sort(function (a, b) {
        if (a.interlocutor > b.interlocutor) {
          return 1;
        }
        if (a.interlocutor < b.interlocutor) {
          return -1;
        }
        return 0;
      });
      this.accounts = new MatTableDataSource(accounts);
      this.accountsFirst = this.accounts.filteredData[0].interlocutor;
      if (this.accountsFirst != undefined || this.accountsFirst != null) {
        this.interlocutorDisabled = false;
        this.accontForm.controls['interlocutor'].setValue(this.accountsFirst);
        this.accontForm.get('interlocutor').disable();
      }
    }, err => {
      this.snackBarService.openSnackBar(5000, err.error.error, '', 'Advertencia', false);
    });
  }

  check(clientServi2Id) {
    this.clientService.activeServi2Account(clientServi2Id).subscribe((response: any) => {
      this.getServi2Accounts();
    }, err => {
      this.snackBarService.openSnackBar(5000, err.error.error, '', 'Advertencia', false);
    });
  }

  accountNew() {
    this.account = !this.account;
  }

  cancelAccountNew() {
    this.accontForm.controls['interlocutor'].reset();
    this.accontForm.controls['cuenta'].reset();
    this.accontForm.controls['instalacion'].reset();
    this.accontForm.controls['password'].reset();
    this.account = !this.account;
  }
  submitForm(){
    console.log(this.accontForm);
  }

  async submitAccount() {
    if  (this.interlocutorDisabled == false) {
      this.addEngieClientIndetifiers = {
        interlocutor: this.accountsFirst,
        accountNumber: this.accontForm.value.cuenta,
        instalationNumber: this.accontForm.value.instalacion
      };
    } else {
      this.addEngieClientIndetifiers = {
        interlocutor: this.accontForm.value.interlocutor,
        accountNumber: this.accontForm.value.cuenta,
        instalationNumber: this.accontForm.value.instalacion
      };
    }

    try {
      const response = await this.clientService.getEngieClientByIdentifiers(this.addEngieClientIndetifiers).toPromise();
      this.validate = true;
      this.snackBarService.openSnackBar(1500, 'Cuenta valida', 'success');
    } catch (err) {
      this.snackBarService.openSnackBar(2000, err.error.error, 'error');
      console.log('ERROR: ', err);
    }
  }

  cancelConfirm() {
    this.validate = !this.validate;
  }

  async addAccount() {
    try {
      this.postAccount = await this.clientService.postAccounts(this.addEngieClientIndetifiers, this.accontForm.value.password).toPromise();
      if (this.postAccount) {
        this.newAccount = {
          active: false,
          clientServi2Id: this.postAccount['userId'],
          cuentaContrato: this.postAccount['CuentaContrato'],
          interlocutor: this.postAccount['Interlocutor'],
          numExterior: this.postAccount['NumExterior'],
          numInterior: this.postAccount['NumInterior'],
          numeroDeInstalacion: this.postAccount['NumeroDeInstalacion']
        };
        this.confirmation.controls['password'].reset();
        this.getServi2Accounts();
        this.cancelConfirm();
        this.cancelAccountNew();
        this.router.navigate([`cuentas`]);
        this.snackBarService.openSnackBar(1500, 'Cuenta añadida con exito', 'success');
      }
    } catch (err) {
      this.snackBarService.openSnackBar(2000, err.error.error, 'error');
      console.log('ERROR: ', err);
    }
  }

  delAccount(account) {
    if (account.active == false) {
      this.confirmDelete = !this.confirmDelete;
      this.deleteEngieClientIndetifiers = {
        interlocutor: account.interlocutor,
        accountNumber: account.cuentaContrato,
        instalationNumber: account.numeroDeInstalacion
      };
    } else {
      this.snackBarService.openSnackBar(2000, 'Una cuenta activa no se puede eliminar', 'error');
    }
  }

  cancelDel() {
    this.confirmDelete = !this.confirmDelete;
  }

  async deleteAcount() {
    this.confirmDelete = !this.confirmDelete;
    const password = this.confirmationDelete.value.password;

    try {
      const response = await this.clientService.deleteAccounts(this.deleteEngieClientIndetifiers, password).toPromise();
      if (response) {
        this.getServi2Accounts();
        this.router.navigate([`cuentas`]);
        this.snackBarService.openSnackBar(1500, 'Cuenta eliminada con exito', 'success');
      }
    } catch (err) {
      this.snackBarService.openSnackBar(2000, err.error.error, 'error');
      console.log('ERROR: ', err);
    }
  }


}
