import {Injectable} from '@angular/core';
import {Subject, Subscribable, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private loaddingSubject = new Subject<any>();

  constructor() {
  }
  private date:string = "";
  public getLoadingObservable(): Subscribable<any> {
    return this.loaddingSubject.asObservable();
  }

  emitBooleanObjects(boolObject) {
    this.loaddingSubject.next(Object.values(boolObject));
  }

   public setCountTipe(dato:string){
    this.date = dato;
  }
  
  public getCountTipe(){
    return this.date;
  }


}
