import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.scss']
})
export class AyudaComponent implements OnInit {
  componentsSubscription: any;
  userId: any;

  constructor(
    private activate: ActivatedRoute,
    private router: Router
  ) {
    this.componentsSubscription = this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });
  }

  ngOnInit() {
  }

}
