import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatButton,
  MatTabsModule,
  MatStepperModule,
  MatOptionModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatRadioModule,
  MatMenuModule,
  MatDialogModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatAutocompleteModule,
} from "@angular/material";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCardModule,
    MatStepperModule,
    MatOptionModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatGridListModule,
    MatCheckboxModule,
    MatDividerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatBadgeModule,
    MatTableModule,
    MatExpansionModule,
    MatMenuModule,
    MatRadioModule,
    MatExpansionModule,
  ],
  exports: [
    MatDatepickerModule,
    MatDialogModule,
    MatCardModule,
    MatStepperModule,
    MatOptionModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatGridListModule,
    MatCheckboxModule,
    MatDividerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatBadgeModule,
    MatTableModule,
    MatExpansionModule,
    MatMenuModule,
    MatRadioModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatAutocompleteModule,
  ],
  providers: [
  ]
})
export class MaterialModule { }
