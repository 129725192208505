import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

/**
 * Component that imitates an item of an angular material accordeon
 */

@Component({
  selector: 'app-custom-mat-panel',
  templateUrl: './custom-mat-panel.component.html',
  styleUrls: ['./custom-mat-panel.component.scss']
})
export class CustomMatPanelComponent implements OnInit {


  @Input() content: string;
  @Input() showArrow: boolean;
  @Input() disabled: boolean;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();


  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }

}
