import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from "../../api";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (private clientService: ClientService,
               private router: Router) {

  }

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authenticatedUser = this.clientService.isAuthenticated();
    const isEngieClient = this.clientService.isEngieClient();
    if(state.url !== '/register')
      this.clientService.setNavigateArgument(state.url);
    if(authenticatedUser) {
      if(state.url === '/register') {
        this.router.navigate(['/home']);
        return false;
      }
      if(!isEngieClient) {
        this.router.navigate(['/homenoengie']);
        return true;
      }
    } else if(state.url !== '/register') {
      this.router.navigate(['/register']);
      return true;
    }
    return true;
  }



}
