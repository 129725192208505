import { map, first, catchError } from 'rxjs/operators';
import { TutenClient, TutenClientProfileProvide, TutenClientProfile } from './client-interfaces';


export function _mapTutenClientProfile() {
  return  map((res: any) => {
    const { 
      id, 
      clientId: { 
        id: clientID, sessionToken, tokenValidationEmail, expirationTokenDate, active, lastActivityDate, creationDate, engie
      },
      authToken,
      email,
      firstName,
      lastname: lastName,
      name,
      numberPhone,
      photoUrl,
      updatedDate,
      creationDate: creationdate,
      tutenClientProfileProviders: providers
    } = res;
    const tutenClient: TutenClient = {
      id: clientID,
      sessionToken,
      tokenValidationEmail,
      expirationTokenDate,
      active,
      lastActivityDate,
      creationDate, engie
    };
    const tutenClientProfileProviders: TutenClientProfileProvide[] = providers.map((providerEl: any) => {
      const { provider, providerId } = providerEl;
      const providerInstance: TutenClientProfileProvide = { provider, providerId };
      return providerInstance;
    });
    const tutenClientProfile: TutenClientProfile = {
      id,
      clientId: tutenClient,
      authToken,
      email,
      firstName,
      lastName,
      name,
      numberPhone,
      photoUrl,
      updatedDate,
      creationDate: creationdate,
      tutenClientProfileProviders
    };
    return tutenClientProfile
  })
}

export function _mapTutenClient(): any {
  return  map((res: any) => {
    const {
      id, sessionToken, tokenValidationEmail, active, lastActivityDate, creationDate, engie, expirationTokenDate
    } = res;
    const client: TutenClient = {
      id,
      sessionToken,
      tokenValidationEmail,
      active,
      lastActivityDate,
      creationDate,
      engie,
      expirationTokenDate
    };
    return client;
  })
}
