
import { Action } from '@ngrx/store';

export const OPEN_LOADING = '[App] Open loading';
export const CLOSE_LOADING = '[App] Close loading';
export const SET_USER_LOGGED = '[App] Set user logged';
export const REMOVE_USER_LOGGED = '[App] Remove user logged';


export class OpenLoadingActions implements Action {
  readonly type = OPEN_LOADING;
}

export class CloseLoadingActions implements Action {
  readonly type = CLOSE_LOADING;
}

export class SetUserLoggedActions implements Action {
  readonly type = SET_USER_LOGGED;
}

export class RemoveUserLoggedActions implements Action {
  readonly type = REMOVE_USER_LOGGED;
}

export type actions = OpenLoadingActions | CloseLoadingActions | SetUserLoggedActions | RemoveUserLoggedActions;

