import { Component } from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/interfaces/app.interface';
import { NavOption } from 'src/app/interfaces/navOption.interface';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {

  headerOptions: NavOption[];

  constructor(private store: Store<AppState>) {
    this.store.select('home').subscribe(
      home => this.headerOptions = home.navOptions
    )
  }

}
