import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-form-mobile',
  templateUrl: './login-form-mobile.component.html',
  styleUrls: ['./login-form-mobile.component.scss']
})
export class LoginFormMobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
