import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
import { MatDialogRef } from '@angular/material';
import { getEngieDataFormGroup } from '../../login/register-component/engie-details/engie-details.component';


@Component({
  selector: 'app-cellphone-register',
  templateUrl: './cellphone-register.component.html',
  styleUrls: ['./cellphone-register.component.scss']
})
export class CellphoneRegisterComponent implements OnInit {
  cellPhoneForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<CellphoneRegisterComponent>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.setCellphoneForm();
  }

  setCellphoneForm() {
    this.cellPhoneForm = new FormGroup({
      cellPhoneNumber: new FormControl('', Validators.compose([Validators.required, this.customNumberValidator])),
      engieAccountDatas: this.formBuilder.array([
        getEngieDataFormGroup(),
      ]),
      isEngie: new FormControl(),
    })
  }

  customNumberValidator(control: AbstractControl): { [key: string]: boolean} | null {
    if(control.value) {
      const countryCode: CountryCode = 'MX' as CountryCode;
      try {
        const phoneNumber = parsePhoneNumber(control.value + '', countryCode);
        return phoneNumber.isValid() ? null : { invalidNumber: true };
      } catch (error) {
        return { invalidNumber: true };
      }
    }
    return null;
  }

  

  
  /**
   * Se encarga de cerrar el modal y enviar o no la data
   * al componente que lo llama.
   * @param formSubmited flag para determinar cuando el formulario fue contestado
   */
  onClose(formSubmited: boolean): void {
    const dataPassed: FormGroup = formSubmited ? this.cellPhoneForm : null;
    this.dialogRef.close(dataPassed);
  }

}
