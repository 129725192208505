import { Component, OnInit, OnDestroy } from '@angular/core';
import { BookingsService } from 'src/app/core/api/bookings/bookings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-no-engie',
  templateUrl: './home-no-engie.component.html',
  styleUrls: ['./home-no-engie.component.scss']
})
export class HomeNoEngieComponent implements OnInit, OnDestroy {

  list: string[] = ['servicio item1','servicio item2', 'servicio item3', 'servicio item4'];

  sercicios: string[] = ['Revision anual de instalación', 'Asistencia de Gas', 'Asistencia Hogar', 'Asistencia Medica'];
  groups: any[];
  asistencias: any[] = [
    {solicitud: 'Revisión anual de instalación de gas', icono: 'ballot'},
    {solicitud: 'Asistencia de gas', icono: 'contact_support'},
    {solicitud: 'Asistencia del hogar', icono: 'home'},
    {solicitud: 'Asistencia médica', icono: 'local_hospital'},
  ];

  // Carrusel de comentario
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      // 400: {
      //   items: 2
      // },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 4
      // }
    },
    nav: false
  };

  componentSubscr: Subscription = new Subscription;

  constructor(public booking: BookingsService) {

  }

  ngOnInit() {
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.componentSubscr.unsubscribe();
  }

  public getServicesGroupsNoId() {
    const getServiceSubscr = this.booking.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        this.groups = response;
        // Parametros para el progressBar
        //this.isLoading.servicesGroupLoading = false;
        //this.progresBarService.emitBooleanObjects(this.isLoading)
      }
    });

    this.componentSubscr.add(getServiceSubscr);
  }

}
