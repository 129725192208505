import { Injectable } from  '@angular/core';
import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
providedIn:  'root'
})

export class ApiService {
  url: string = environment.url + '/Servi2ClientsREST/rest';

  constructor(private  http:  HttpClient) {}

  get(endpoint: string, params?: any, reqOpts?: any, headers?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    if (headers) {
      reqOpts.headers = new HttpHeaders();
      for (const k in headers) {
        reqOpts.headers = reqOpts.headers.set(k, String(headers[k]));
      }
    }
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.url + '/' + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, headers?: any , params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {};
    }
    if (headers) {
      reqOpts.headers = new HttpHeaders();
      for (let k in headers) {
        reqOpts.headers = reqOpts.headers.set(k, String(headers[k]));
      }
    }
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return this.http.post(this.url + '/' + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, headers?: any , params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {};
    }
    if (headers) {
      reqOpts.headers = new HttpHeaders();
      for (let k in headers) {
        reqOpts.headers = reqOpts.headers.set(k, headers[k]);
      }
    }
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any, headers?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    if (headers) {
      reqOpts.headers = new HttpHeaders();
      for (let k in headers) {
        reqOpts.headers = reqOpts.headers.set(k, String(headers[k]));
      }
    }
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }

  timezone(lat, lng) {
    return this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location="+lat+","+lng+"&timestamp="+new Date().getTime() / 1000+"&key=AIzaSyB3PlzlhobsZ8tXcr5gBx14uq348T-uKjM");
  }

}
