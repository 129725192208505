export const API_MAIN_URL = 'https://devservi2.tuten.cl/clientes';


export const statesBooking = {
  STATE_REJECTED: -1,
  STATE_BY_SCHEDULE: 9,
  STATE_CREATED: 0,
  STATE_SCHEDULED: 10,
  STATE_ON_WAY: 5,
  STATE_ACCEPTED: 1,
  STATE_INITIATED: 4,
  STATE_WAIT_FOR_A_BUDGET: 6,
  STATE_SUPPLIER_BUDGET: 7,
  STATE_OPERATIONS_BUDGET: 8,
  STATE_CLIENT_BUDGET: 11,
  STATE_FINISHED_SUCCESSFULLY: 3,
  STATE_FINISHED_WITH_INCIDENCE: -5,
  STATE_CANCELED_BY_CLIENT: -2,
  STATE_ARRIVAL: 14
};

export function getSelectObject() {
  const statesBookingArr =  Object.values(statesBooking).map( value => {
    switch (value) {
      case statesBooking.STATE_CREATED:
        return { value, name: "Creado" };
      case statesBooking.STATE_BY_SCHEDULE:
        return { value, name: "Por Agendar" };
      case statesBooking.STATE_SCHEDULED:
        return { value, name: "Agendado" };
      case statesBooking.STATE_ACCEPTED:
        return { value, name: "Aceptado" };
      case statesBooking.STATE_REJECTED:
        return { value, name: "Rechazado" };
      case statesBooking.STATE_ON_WAY:
        return { value, name: "En camino" };
      case statesBooking.STATE_INITIATED:
        return { value, name: "Iniciado" };
      case statesBooking.STATE_WAIT_FOR_A_BUDGET:
        return { value, name: "Espera de presupuesto" };
      case statesBooking.STATE_OPERATIONS_BUDGET:
        return { value, name: "Presupuesto en operaciones" };
      case statesBooking.STATE_SUPPLIER_BUDGET:
        return { value, name: "Presupuesto en proveedores" };
      case statesBooking.STATE_FINISHED_SUCCESSFULLY:
        return { value, name: "Concluido satisfactoriamente" };
      case statesBooking.STATE_FINISHED_WITH_INCIDENCE:
        return { value, name: "Concluido con incidencia" };
      case statesBooking.STATE_CANCELED_BY_CLIENT:
        return { value, name: "Cancelado por el cliente" };
      default:
        return "";
    }
  });
  return statesBookingArr;
}
export function getFinishObject() {

  const statesBookingArr =  Object.values(statesBooking).map( value => {
    switch (value) {
      case statesBooking.STATE_FINISHED_SUCCESSFULLY:
        return { value, name: "Concluido satisfactoriamente" };
      case statesBooking.STATE_FINISHED_WITH_INCIDENCE:
        return { value, name: "Concluido con incidencia" };
      case statesBooking.STATE_CANCELED_BY_CLIENT:
        return { value, name: "Cancelado por el cliente" };
      default:
        return "";
    }
  });
  return statesBookingArr;
}

export const ACTIONS_POLL = {
  ACTION_CHECKBOX_SIMPLE: 1,
  ACTION_BOOLEAN: 2,
  ACTION_TEXT_INPUT: 3,
  ACTION_TEXTAREA: 4,
  ACTION_DATETIME: 5,
  ACTION_SELECT: 6,
  ACTION_SELECT_MULTIPLE: 7,
  ACTION_LEVEL: 8
};


/**
 * public final static String COST_VISIT = "Visita";
    public final static String COST_WORKFORCE = "Mano de Obra";
    public final static String COST_MATERIALS = "Materiales";
    public final static String COST_WARRANT = "Garantía";
    public final static String COST_REFUND = "Reembolso";
 */

export const COST_LINES = {
  COST_VISIT: "Visita",
  COST_WORKFORCE: "Mano de Obra",
  COS_MATERIALS: "Materiales",
  COST_TWARRANT: "Garantía",
  COST_REFUND: "Reembolso",
};

export const MESSAGE_TIMES = {
  ERROR_MESSAGE: 5000,
  SUCCESS_MESSAGE: 5000,
  WARNING_MESSAGE: 5000
};

