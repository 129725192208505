import { Component } from '@angular/core';

@Component({
  selector: 'banner-home-details',
  templateUrl: './banner-home-details.component.html',
  styleUrls: ['./banner-home-details.component.scss']
})
export class BannerHomeDetailsComponent{

  constructor() {}
}
