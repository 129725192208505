import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit, OnDestroy {

  div: any;

  constructor(public dialogRef: MatDialogRef<LogoutDialogComponent>) { }

  ngOnInit() {
  }

  ngOnDestroy () {
    this.dialogRef = null;
  }

  onClose() {
    this.dialogRef.close();
  }

}
