import * as actionTypes from '../actions/app.actions';
import { AppModel } from '../models/app.model';

const initialState: AppModel = {
  loading: false,
  isLogged: false
};

export function appReducer ( state = initialState, action: actionTypes.actions) {
  let newState = Object.assign({}, state);
  switch(action.type){
    case actionTypes.OPEN_LOADING:
      newState.loading = true;
      return newState;
    case actionTypes.CLOSE_LOADING:
      newState.loading = false;
      return newState;

    case actionTypes.SET_USER_LOGGED:
      newState.isLogged = true;
      return newState;

    case actionTypes.REMOVE_USER_LOGGED:
      newState.isLogged = false;
      return newState;

    default:
      return newState;
  }
}
