import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {ProgressBarService} from "../../../core/api/progress-bar.service";

@Component({
  selector: 'app-progresbar',
  templateUrl: './progresbar.component.html',
  styleUrls: ['./progresbar.component.scss']
})
export class ProgresbarComponent implements OnInit {

  public loadingSubscription : any ;
  public isLoading = true;

  constructor(private progresBarServices: ProgressBarService) { }

  ngOnInit() {
    this.setLoadingSubscription();
  }

  private setLoadingSubscription(): void {
    this.loadingSubscription = this.progresBarServices.getLoadingObservable().subscribe(data => {
      this.isLoading = Boolean(data.find(d => d));
    });
  }

}
