import {
  Directive,
  Input,
  OnInit
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material';
import { map } from 'rxjs/operators';

export interface ResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

// Usage: <mat-grid-list [responsiveColumns]="{xs: 1, sm: 2, md: 4, lg: 6, xl: 12}">
@Directive({
             selector: '[responsiveColumns]'
           })
export class ResponsiveColumnsDirective implements OnInit {

  //Set to default to the own use case at guia-rapida comonent
  private static readonly DEFAULT_COLUMNS_MAP: ResponsiveColumnsMap = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3
  };

  @Input() private responsiveColumns: ResponsiveColumnsMap;

  constructor(private readonly grid: MatGridList,
              private readonly mediaObserver: MediaObserver) {
  }

  ngOnInit(): void {
    this.responsiveColumns = this.responsiveColumns || ResponsiveColumnsDirective.DEFAULT_COLUMNS_MAP;

    this.initializeColsCount();
    this.mediaObserver.media$.pipe(
          map((change: any) => {
            const matchingAliases = this.mapAlias(change.mqAlias);
            const alias = Boolean(matchingAliases)
                          ? matchingAliases
                          : 'xs';
            return this.responsiveColumns[ alias ];
          })
        ).subscribe(cols => {
          this.grid.cols = cols
        });
  }

  private initializeColsCount(): void {
    const matchingAliases = Object.keys(this.responsiveColumns)
                                  // sort by number of columns desc
                                  .sort((a, b) => this.responsiveColumns[ b ] - this.responsiveColumns[ a ])
                                  // doublecheck
                                  .filter(alias => this.mediaObserver.isActive(alias));

    if (matchingAliases.length > 0) {
      const firstMatchingAlias = matchingAliases[ 0 ];
      this.grid.cols           = this.responsiveColumns[ firstMatchingAlias ];
    } else {
      this.grid.cols = this.responsiveColumns.xs;
    }
  }

  private mapAlias(mqAlias: string): string {
    if (!mqAlias.includes('-')) {
      return mqAlias;
    }

    const parts  = mqAlias.split('-');
    const ltOrGt = parts[ 0 ];
    const alias  = parts[ 1 ];

    const keys  = Object.keys(this.responsiveColumns);
    const index = keys.indexOf(alias);

    return ltOrGt === 'lt'
           ? keys[ index - 1 ]
           : keys[ index + 1 ];
  }
}