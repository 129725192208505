import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormArray, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TutenEngieClientIndentifier } from 'src/app/core/api/client/client-interfaces';
import { ClientService } from 'src/app/core/api';
import { Subscription } from 'rxjs';
import { API_MAIN_URL, MESSAGE_TIMES } from 'src/app/components/shared/constants';
import { SnackbarService } from 'src/app/core/api/snackbar.service';


export function getClientIdsFromForm(isEngie, formGroup: FormGroup): number[] {
  if (Boolean(isEngie).valueOf()) {
    const engieDataFormArray: FormArray = formGroup.get('engieAccountDatas') as FormArray;
    return engieDataFormArray.controls.map((formGroup: FormGroup) => {
      return formGroup.get('id').value
    });
  }
  return [];
}

/**
   * Function to get the engie Client
   * data form group
   */

export function getEngieDataFormGroup(): FormGroup {
  const formBuilder = new FormBuilder();
  return formBuilder.group({
    interlocutor: new FormControl('', Validators.required),
    accountNumber: new FormControl('', Validators.required),
    // instalationNumber: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required)
  })
}

@Component({
  selector: 'engie-details',
  templateUrl: './engie-details.component.html',
  styleUrls: ['./engie-details.component.scss']
})
export class EngieDetailsComponent implements OnInit, OnDestroy {

  @Input()
  registerFormGroup: FormGroup;
  errorEngieMessage: string[] = [];
  componentSubs: Subscription = new Subscription();
  url: string = API_MAIN_URL;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private snackBarService: SnackbarService
  ) { }

  ngOnInit() {
    this.setIsEngieValidators();
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }

  /**
   * Functio to add or remove engieFormGroup to arrayForm
   * @param action true Add, false remove
   * @param index if remove, especify the index of element to remove
   */
  addOrRemoveEngieFormGroup(action: boolean, index?: number): void {
    const engieDataFormArray: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;
    // Add a form Group
    const formArrayLength: number = engieDataFormArray.length;
    if (action) {
      const prevFormGroupIsValid: boolean = engieDataFormArray.get((formArrayLength - 1).toString()).valid;
      //If valid then add
      if (prevFormGroupIsValid)
        engieDataFormArray.push(this.getEngieDataFormGroup(engieDataFormArray.value[0].interlocutor));
      return;
    }
    if (formArrayLength - 1 !== 0 && index != null && index <= formArrayLength - 1) {
      engieDataFormArray.removeAt(index)
    } else {
      engieDataFormArray.at(index).reset();
    }
  }

  /**
   * Function to get the engie Client
   * data form group
   */

  getEngieDataFormGroup(interlocutor?: string): FormGroup {
    return this.formBuilder.group({
      interlocutor: new FormControl(interlocutor ? interlocutor : '', Validators.required),
      accountNumber: new FormControl('', Validators.required),
      // instalationNumber: new FormControl('', Validators.required),
      id: new FormControl('', Validators.required)
    })
  }

  /**
 * Function that triggers a request to get the
 * userInfo or adds another row to the form
 * @param invalid when the id at he form isn't valid it means that need to bee validated
 */
  async validateEngieClient(invalid: boolean, index: number) {
    if (invalid) {
      const isEngieData: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;
      const targetFormGroup: FormGroup = isEngieData.get(index.toString()) as FormGroup;
      // const { interlocutor: { value: interlocutor }, accountNumber: { value: accountNumber }, instalationNumber: { value: instalationNumber } } = targetFormGroup.controls;
      const { interlocutor: { value: interlocutor }, accountNumber: { value: accountNumber } } = targetFormGroup.controls;
      const engieClientIndetifiers = {
        interlocutor,
        accountNumber
      };
      //Se colocan ceros prefijo en estos dos códigos.
      engieClientIndetifiers.accountNumber = "00" + accountNumber;
      try {
        this.errorEngieMessage[index] = null;
        const response = await this.clientService.getEngieClientByIdentifiersWithoutInstalationNumber(engieClientIndetifiers).toPromise();
        const { userId } = response;
        let canUpdate = true;
        for (let i = 0; i < isEngieData.controls.length; i++) {
          canUpdate = isEngieData.controls[i].value.id != userId;
          if (!canUpdate)
            break;
        }
        if (canUpdate) {
          targetFormGroup.get('id').setValue(userId);
          targetFormGroup.updateValueAndValidity();
          this.registerFormGroup.updateValueAndValidity();
          this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, "Cuenta engie agregada", "success", "Exito", false);
        } else {
          this.errorEngieMessage[index] = "Ya agregaste este contrato"
        }
      } catch (errResponse) {
        if (errResponse.error) {
          this.errorEngieMessage[index] = errResponse.error.error ===
            "No se encontro cliente servi2" ? "No se encontró cuenta engie" : errResponse.error.error;
        }
      }
    }
  }

  /**
   * Function to make the isEngie user validation
   * dynamic
   */
  setIsEngieValidators(): void {
    const formSub = this.registerFormGroup.get('isEngie')
      .valueChanges.subscribe((isEngie: boolean) => {
        //TODO: specify validation of interlocutor and accountNumber
        const isEngieData: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;
        isEngieData.setValidators(isEngie ? Validators.required : null);
        isEngieData.updateValueAndValidity();
        isEngieData.controls.forEach((formGroup: FormGroup) => {
          Object.keys(formGroup.controls).forEach((control) => {
            formGroup.get(control).setValidators(isEngie ? Validators.required : null);
            formGroup.get(control).updateValueAndValidity();
          });
          formGroup.updateValueAndValidity();
        });
        this.registerFormGroup.updateValueAndValidity();
      });
    this.componentSubs.add(formSub);
  }
  // !!Boolean(registerFormGroup.get('engieAccountDatas').value[0].interlocutor.value)
}
